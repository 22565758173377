body {
    text-align: center;
    height: 100vh;
    font: 16px "Century Gothic", Futura, sans-serif;
    margin: 0px;
}

.title {
    height: 15vh;
    background-color: rgba(255, 255, 0, 0.4);
    line-height: 20vh;
}

.successtitle {
    height: 15vh;
    background-color: rgba(0, 255, 255, 0.4);
    line-height: 20vh;
}

.canceltitle {
    height: 15vh;
    background-color: rgba(255, 50, 0, 0.4);
    line-height: 20vh;
}

.titlefont {
    font-size: 30px;
}

.content {
    height: 85vh;
    padding-top: 10vh;
    position: relative;
}

.desc {
    font-size: 14px;
}

.navs {
    position: fixed;
    bottom: 0;
}

ol,
ul {
    list-style: none;
    padding-left: 0px;
}

* {
    margin: 0px;
    padding: 0px;
}

@media screen and (min-width: 481px) {
    .titlefont {
        font-size: 58px;
    }
    .desc {
        font-size: 24px;
    }
    ul {
        display: flex;
        flex-wrap: wrap;
    }
    li {
        width: 50%;
        box-sizing: border-box;
        padding: 10px;
    }
}

@media screen and (min-width: 960px) {
    .successtitle {
        height: 20vh;
        background-color: rgba(0, 255, 255, 0.4);
        line-height: 23vh;
    }
    .canceltitle {
        height: 20vh;
        background-color: rgba(255, 50, 0, 0.4);
        line-height: 23vh;
    }
    .titlefont {
        font-size: 80px;
    }
    .desc {
        font-size: 48px;
    }
    li {
        width: 33%;
    }
}